<template>
  <MainLayout fluid>
    <h1 class="mb-4">
      <i class='bx bx-import'></i>
      {{ $t('import.title') }}
    </h1>

    <router-view></router-view>
 
  </MainLayout>
</template>

<script>
import MainLayout from '@/layouts/Main'

export default {
    components: { MainLayout },

}
</script>