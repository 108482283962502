<template>
  <b-form-group>
    <template #description>
      <span v-html="description"></span>
    </template>
    <v-validate 
      ref="validator" 
      v-bind="{name, rules, immediate:true }" 
      v-slot="{ errors, touched, valid, validate }">
      <legend v-if="label" tabindex="-1" class="bv-no-focus-ring col-form-label pt-0">
        <b-badge v-if="required" class="float-right text-uppercase font-weight-normal" :variant="touched && !valid ? 'danger' : 'light'">required</b-badge>
        <i v-if="icon" :class="['bx', icon]" />
        {{ $t(label) | ucfirst}}
      </legend>

      <b-input-group>
        <b-input-group-prepend>
          <SelectCountry 
            :initialValue="value.country" 
            :clearable="false" 
            v-bind="{disabled}"
            phone 
            class="w-100" 
            @input="setCountry($event,validate)"
          />
        </b-input-group-prepend>
        <b-form-input 
          v-model="value.phone"
          @keypress="onKeypress"
          @blur="touch(validate)"
          @input="$emit('input',value)"
          type="tel"
          v-bind="{name, required, disabled}"
          :state="state(rules, touched, valid)"
        ></b-form-input>
      </b-input-group>
      
      <b-form-invalid-feedback v-if="!noErrors" :state="state(rules, touched, valid)">
        <ul class="list-unstyled m-0">
          <li v-for="(error,i) in errors" :key="i" v-text="error" />
        </ul>
      </b-form-invalid-feedback>

    </v-validate>
  </b-form-group>
</template>

<script>
import SelectCountry from '@/components/Form/SelectCountry.vue'
import { 
  ValidationProvider as vValidate, 
} from 'vee-validate';

export default {
  components: {
    SelectCountry,
    vValidate
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    name: String,
    label: String,
    immediate: Boolean,
    required: Boolean,
    disabled: Boolean,
    description: String,
    noErrors: Boolean,
    icon: {
      type: String,
      default: 'bx-phone'
    },
  },
  computed: {
    rules() {
      return {
        required: this.required,
        phone: {
          country: this.value.country
        }
      }
    }
  },
  methods: {
    touch(validate) {
      this.$nextTick(() => validate(this.value.phone))
    },
    onKeypress(val) {
      if(isNaN(Number(val.key))) {
        return val.preventDefault();
      }
    },
    state(rules, touched, valid) {
      return rules && touched 
        ? (valid ? (!this.value.phone && !this.required ? null : true) : false) 
        : null
    },
    setCountry(country,validate) {
      this.value.country = country
      this.$emit('input',this.value)
      this.touch(validate)
    }
  }
}
</script>