<template>
  <div>
    <b-card>
      <template #header>
        <i class='bx bx-upload'></i>
        {{$t('import.upload.title')}}
      </template>

      <form-input 
        v-if="!userIsMerchant"
        label="merchants.singular" 
        icon="bx-cart"
        type="item"
        v-model="merchant_id"
        resource-name="merchants"
        rules="required"
        :clearable="false"
        style="max-width: 30rem;"
      />

      <form-input 
        label="import.upload.file" 
        icon="bx-file"
        type="file"
        v-model="file"
        rules="required"
        :placeholder="$t('import.upload.placeholder')"
        :drop-placeholder="$t('import.upload.drop-placeholder')"
        accept=".xlsx, .xls, .ods, .csv"
        style="max-width: 30rem;"
      />
    </b-card>

    <b-card class="mb-2">
      <b-button :disabled="!valid" @click="upload" variant="primary" class="float-right">
        <i class='bx bx-upload' ></i>
        {{ $t('import.upload.next') }}
      </b-button>

      <b-button variant="info" @click="downloadTemplate">
        <i class='bx bxs-download' ></i>
        {{ $t('import.upload.download-template') }}
      </b-button>
      <!-- <b-button v-else @click="cancel">
        {{ $t('common.cancel') }}
      </b-button> -->
    </b-card>
  </div>
</template>

<script>
import api from '@/api'
import FormInput from "@/components/Form/Input";
import { mapGetters } from 'vuex';
import { download } from '@/utils';

export default {
  components: { FormInput },
  data() {
    return {
      merchant_id: null,
      file: null,
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      userIsMerchant: 'auth/isMerchant',
    }),
    valid() {
      return Boolean(this.file) && (this.userIsMerchant || this.merchant_id)
    }
  },
  methods: {
    async downloadTemplate() {
      download(await api.get('shipments/import/template',{responseType: 'blob'}))
    },
    async upload() {
      let formData = new FormData()
      formData.append('file', this.file)
      formData.append('merchant_id', this.merchant_id)
      let {data} = await api.post('shipments/import/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
      })

      this.$router.push({
        name: 'shipments-import-preview',
        params: {
          data,
          merchant_id: this.userIsMerchant ? this.user.merchant_id : this.merchant_id,
        }
      })
    },
  },
  metaInfo() {
    return {
      title: `${this.$t('import.title')} - ${this.$t('import.upload.title')}`,
    }
  },

}
</script>