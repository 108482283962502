<template>
  <div>
    <v-validate-observe ref="observer" v-slot="{ invalid }">
      <b-card no-body>
        <template #header>
          <i class='bx bx-spreadsheet'></i>
          {{$t('import.preview.title')}}
        </template>

        <table class="table table-sm table-responsive">
          <thead>
            <tr>
              <th></th>
              <th v-for="(label, field) in fields" :key="field" nowrap>
                {{$t(label)}}
                <span v-if="requiredFields.includes(field)" class="text-muted">*</span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row,i) in internalData" :key="i">
              <td>
                <b-button variant="danger" @click="removeRow(i)"><i class='bx bx-trash'></i></b-button>
              </td>
              <td><form-input class="fw-2" rules="required" v-model="internalData[i].external_reference_id" /></td>
              <td><form-input class="fw-2" rules="required" v-model="internalData[i].first_name" /></td>
              <td><form-input class="fw-2" rules="required" v-model="internalData[i].last_name" /></td>
              <td>
                <PhoneInput :name="`${i}-phone`" class="fw-2" required :value="{country: row.country, phone: row.phone}" @input="setPhone(i,$event)" />
              </td>
              <td><form-input class="fw-2" rules="email" v-model="internalData[i].email" /></td>
              <td>
                <form-input
                  type="select"
                  :options="pickupLocations"
                  v-model="internalData[i].pickup_location_id"
                  rules="required"
                  :clearable="false"
                  class="fw-2"
                />
              </td>
              <td>
                <form-input
                  type="select"
                  :options="dropoffLocations"
                  v-model="internalData[i].dropoff_location_id"
                  rules="required"
                  :clearable="false"
                  class="fw-2"
                />
              </td>
              <td><form-input class="fw-0 tight" type="number" v-model.number="internalData[i].packages_small" /></td>
              <td><form-input class="fw-0 tight" type="number" v-model.number="internalData[i].packages_medium" /></td>
              <td><form-input class="fw-0 tight" type="number" v-model.number="internalData[i].packages_large" /></td>
            </tr>
          </tbody>
        </table>
        <template #footer>
          * {{$t('import.preview.required-field')}}
        </template>
      </b-card>

      <b-card>
        <b-button :disabled="invalid" @click="save" variant="primary" class="float-right">
          <i class='bx bx-save' ></i>
          {{ $t('import.preview.next') }}
        </b-button>

        <b-button @click="$router.push({name: 'shipments-import'})">
          {{ $t('common.cancel') }}
        </b-button>
      </b-card>
    </v-validate-observe>
    <debug :data="{data:internalData}" />
  </div>
</template>

<script>
import Debug from '@/components/Debug.vue'
import FormInput from "@/components/Form/Input"
import PhoneInput from "@/components/Form/PhoneInput"
import { 
  ValidationObserver as vValidateObserve, 
} from 'vee-validate';
import Location from '@/resources/Location';

let fields = {
  "external_reference_id": "shipments.external-reference",
  "first_name": "common.first_name",
  "last_name": "common.last_name",
  "phone": "common.phone",
  "email": "common.email",
  // "country": "common.country",
  "pickup_location_id": "shipments.pickup-location",
  "dropoff_location_id": "shipments.dropoff-location",
  "packages_small": "packages.sizes.SMALL",
  "packages_medium": "packages.sizes.MEDIUM",
  "packages_large": "packages.sizes.LARGE",
}

export default {
  components: { 
    Debug, 
    FormInput, 
    vValidateObserve,
    PhoneInput
  },
  props: {
    data: {
      type: Array,
      required: true
    },
    merchant_id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      fields,
      internalData: this.data,
      requiredFields: ["external_reference_id", "name", "phone", "country", "pickup_location_id", "dropoff_location_id"],
      pickupLocations: [],
      dropoffLocations: [],
    }
  },
  async mounted() {
    await this.$refs.observer.validate()

    this.pickupLocations = await Location.list({ light: true, scope: ['pickup','active','approved',['merchant', this.merchant_id]] });
    this.dropoffLocations = await Location.list({ light: true, scope: [['dropoff',this.merchant_id],'active'] });

    this.dropoffLocations.push({
      code: 'request',
      label: this.$t('shipments.request-dropoff-option')
    })

    for (let field in this.$refs.observer.refs) {
      this.$refs.observer.refs[field].setFlags({touched: true})
    }
  },
  methods: {
    async save() {
      this.$router.push({
        name: 'shipments-import-save',
        params: {
          data: this.data,
          merchant_id: this.merchant_id,
        }
      })
    },
    setPhone(i,$event) {
      this.$set(this.internalData,i,{...this.internalData[i],...$event})
    },
    removeRow(i) {
      this.internalData.splice(i,1)
    }
  },
  beforeRouteEnter(to, from, next) {
    if('data' in to.params && to.params.merchant_id) next()
    else next({name: 'shipments-import'})
  },
  metaInfo() {
    return {
      title: `${this.$t('import.title')} - ${this.$t('import.preview.title')}`,
    }
  }
}
</script>

<style scoped>
  .fw-0 {
    min-width: 5rem;
  }

  .fw-1  {
    min-width: 150px;
  }

  .fw-2  {
    min-width: 250px;
  }

  .fw-3  {
    min-width: 300px;
  }
</style>