<template>
  <div>
    <b-card>
      <template #header>
        <i class='bx bx-save'></i>
        {{$t('import.save.title')}}
      </template>

      <template v-if="finished">{{$t(`import.save.finished`,{count: $tc('shipments.pluralize',progress)})}}</template>
      <template v-else>{{$t('import.save.saving')}}</template>
      
      <b-progress :max="total" :value="progress" :animated="!finished" variant="info" />

    </b-card>

    <b-card>
      <b-button :disabled="!finished" @click="$router.push({name: 'shipments'})" variant="primary" class="float-right">
        <i class='bx bx-truck' ></i>
        {{ $t('import.save.goto-shipments') }}
      </b-button>

      <b-button :disabled="!finished" @click="$router.push({name: 'shipments-import'})">
        <i class='bx bx-import' ></i>
        {{ $t('import.save.goto-import') }}
      </b-button>
    </b-card>

    <debug :data="{data}" />
  </div>
</template>

<script>
// import api from '@/api'
import Debug from '@/components/Debug.vue'
import Shipment from '@/resources/Shipment'

export default {
  components: { Debug },
  props: {
    data: {
      type: Array,
      required: true
    },
    merchant_id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      total: this.data.length,
      progress: 0,
    }
  },
  computed: {
    finished() {
      return this.progress == this.total
    }
  },
  created() {
    this.createItems()
  },
  methods: {
    async createItems() {
      let items = this.data.map(row => new Shipment({
        ...row,
        merchant_id: this.merchant_id
      }))

      items.forEach(shipment => {
        shipment.$save()
        this.progress++
      })
    },
  },
  beforeRouteEnter(to, from, next) {
    if('data' in to.params && to.params.merchant_id) next()
    else next({name: 'shipments-import'})
  },
  metaInfo() {
    return {
      title: `${this.$t('import.title')} - ${this.$t('import.save.title')}`,
    }
  },
}
</script>